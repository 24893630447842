














































































































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { DownloadProvider, ApiService } from '@/core/services';
import { OrganisationModel, StudyModel } from '@/core/webapi';

@Component
export default class ExportView extends AppVue {
  internalStudies: StudyModel[] | null = null;
  organisations: OrganisationModel[] | null = null;
  selectedOrganisationId: number | null = null; // For MA's
  selectedStudyId: number | null = null; // For reporters and analysts
  exportType: string | null = null;
  isExportTypeSet = false;

  get studies() {
    if (this.isReporter || this.isAnalyst) {
      return this.internalStudies ?? [];
    } else if (this.isMedicalAdmin && this.selectedOrganisationId) {
      return this.organisations?.find(p => p.id === this.selectedOrganisationId)?.studies ?? [];
    }

    return [];
  }

  async created() {
    if (this.isReporter) {
      this.internalStudies = (await ApiService.studies().getReporterExportableStudiesLookupData()).data;
    } else if (this.isAnalyst) {
      this.internalStudies = (await ApiService.studies().getAnalystExportableStudiesLookupData()).data;
    } else {
      this.internalStudies = [];
    }

    if (this.isMedicalAdmin) {
      this.organisations = (await ApiService.organisations().getMedicalAdminExportableOrganisationsLookupData()).data;
      this.preselectOrganisationId();
    }

    this.preselectStudyId();
  }

  preselectOrganisationId() {
    this.selectedOrganisationId = this.organisations && this.organisations.length > 0 ? this.organisations[0].id : null;
  }

  preselectStudyId() {
    this.selectedStudyId = this.studies && this.studies.length > 0 ? this.studies[0].id : null;
  }

  onOrganisationChange() {
    this.preselectStudyId();
  }

  exportCsv(type: string) {
    this.exportType = type;
    this.isExportTypeSet = true;
  }

  async confirmCsvExport() {
    if (this.exportType) {
      await ApiService.export().acceptCsvExportDisclaimer(this.exportType);
      await (this as any)[this.exportType]();
    }

    this.exportType = null;
    this.isExportTypeSet = false;
  }

  async cancelCsvExport() {
    this.exportType = null;
    this.isExportTypeSet = false;
  }

  async reporterInjuries() {
    const data = (await ApiService.export().reporterInjuriesCsv(this.selectedStudyId!)).data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Injuries - ${this.getOrganisationAndStudyName()}`);
  }

  async reporterIllnesses() {
    const data = (await ApiService.export().reporterIllnessesCsv(this.selectedStudyId!)).data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Illnesses - ${this.getOrganisationAndStudyName()}`);
  }

  async reporterExposures() {
    const data = (await ApiService.export().reporterExposuresCsv(this.selectedStudyId!)).data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - Exposures - ${this.getOrganisationAndStudyName()}`);
  }

  async analystStudy() {
    const data = (await ApiService.export().analystStudyCsv(this.selectedStudyId!)).data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - ${this.getOrganisationAndStudyName()}`);
  }

  async medicalAdminOrganisationStudy() {
    const data = (
      await ApiService.export().medicalAdminOrganisationStudyCsv(this.selectedOrganisationId!, this.selectedStudyId!)
    ).data;
    DownloadProvider.downloadAsCsv(data, `World Rugby ISS - ${this.getOrganisationAndStudyName()}`);
  }

  getOrganisationAndStudyName() {
    let name = '';

    if (this.selectedOrganisationId) {
      name += this.organisations?.find(p => p.id === this.selectedOrganisationId)?.name ?? '';
      if (!!name) {
        name += ' - ';
      }
    }

    if (this.selectedStudyId) {
      name += this.studies?.find(p => p.id === this.selectedStudyId)?.name ?? '';
    }

    return name;
  }

  async all() {
    if (this.allowInjuryAndIllnessData) {
      this.reporterInjuries();
      this.reporterIllnesses();
    }
    if (this.allowExposureData) {
      this.reporterExposures();
    }
  }
}
